<template>
    <div>
    </div>
</template>
<style scoped>
</style>
<script>
import { toast, toFirstPage, isWx } from '@/util';
import { loginByWxCode } from '@/services/passport';
import { getUser } from '@/services/user';
import { WX_APPID, H5_HOST } from '../../../config';

export default {
    data() {
        return {
            appid: WX_APPID,
            scope: 'snsapi_userinfo',
            redirectUri: encodeURIComponent(`${H5_HOST}/passport/wxAuthLogin`),
            wxCode: ''
        };
    },
    async created() {
        const { invitorId } = this.$route.query;
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        if (invitorId) {
            localStorage.setItem('invitorId', invitorId);
        }
        await this.checkLogin();
    },
    methods: {
        // 校验用户是否登录
        async checkLogin() {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');
            if (userId && token) {
                const { code, message, data } = await getUser();
                if (!code) {
                    return toast(message);
                }

                return toFirstPage(this.$router, data);
            }
            const { code, state } = this.$route.query;
            if (code && state === 'STATE') {
                this.wxCode = code;
                this.loginByWxCode();
                return;
            }
            if (isWx()) {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirectUri}&response_type=code&scope=${this.scope}&state=STATE&connect_redirect=1#wechat_redirect`;
            }
        },
        async loginByWxCode() {
            const { code, data } = await loginByWxCode({
                wxCode: this.wxCode
            });
            if (code) {
                const { userId, token, phone, openId, unionId, nickName, headImgURL, sex } = data;

                if (userId == 0 || phone == null || phone == '') {
                    let wxInfo = {
                        openId: openId,
                        unionId: unionId,
                        nickName: nickName,
                        headImgURL: headImgURL,
                        sex: sex
                    };
                    localStorage.setItem('wxInfo', JSON.stringify(wxInfo));
                    return this.$router.replace({ path: '/passport/login' });
                } else {
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('token', token);
                    await this.getUser();
                }
            } else {
                return;
            }
        },
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            await this.$store.dispatch('setUserInfo', data);
            toFirstPage(this.$router, data);
        }
    }
};
</script>
